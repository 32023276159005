.message {
  position: fixed;
  z-index: 1073;
  left: 50%;
  top: 11%;
  transform: translateX(-50%);
}
.message p {
  margin: 0;
}
