.table {
  td,
  th {
      border-top: 1px solid $theme-border;
      white-space: nowrap;
      font-size: 13px;
      padding: 4px 4px;
      vertical-align: middle;
  }

  thead th {
      border-bottom: 1px solid $theme-border;
  }

  tbody + tbody {
      border-top: 2px solid $theme-border;
  }

  &.table-sm {
      th, td {
          padding: 0.35rem;
      }
  }

  tr {
    cursor: pointer;
  }

  .image-column {
    width: 60px;
    max-height: 60px;
    
    img {
        max-width: 50px;
        max-height: 50px;
    }
  }
}

.react-bootstrap-table-pagination {
  max-height: 30px;
}

.table-dimmer {
  filter: opacity(.2);
}

