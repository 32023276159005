.image-dropzone {
  cursor: pointer;

  display: block;
  height: 70px;
  width: 100%;
  margin: 10px 15px;

  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.image-remove {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.preview-container {
  height: auto;
  position: relative;
  width: 1400px;
  &__image {
    position: relative;
    width: 90%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: grid;
    .announcement-big {
      margin: auto;
      width: 90%;
    }
    &-header {
      position: absolute;
      line-height: 22px;
      max-width: 400px;
      font-size: 22px;
      font-weight: 400;
      padding: 25px 0 0 105px;
    }
    &-discription {
      position: absolute;
      padding: 7px 0 0 105px;
      max-width: 400px;
      font-weight: 200;
      align-self: center;
      line-height: 15px;
      font-size: 16px;
      font-style: italic;
    }
    &-additionalText {
      position: absolute;
      max-width: 400px;
      padding: 7px 0 5px 105px;
      align-self: flex-end;
    }
  }
}
