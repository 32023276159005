.custom-select-status {
  background: $theme-background;
  padding: 0 14px;
  font-size: 14px;
  height: auto;

  &.form-control-lg {
    font-size: 1.25rem;
  }

  &.form-control-sm {
    height: calc(1.8125rem + 2px);
    font-size: .875rem;
  }

  &.form-control-range {
    box-shadow: none;
  }

  &:focus {
    background: $theme-background;
  }

  &::-moz-placeholder {
    color: #aaaeb3;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #aaaeb3;
  }

  &::-webkit-input-placeholder {
    color: #aaaeb3;
  }
}

.field-error {
  color: red;
}
.chrome-picker {
  position: absolute;
  z-index: 2;
}
.delete-button {
  float: right
}
