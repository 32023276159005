.tab-content {
    padding-bottom:20px;
}
.product-button {
  margin: 15px 10px 0px;
}

.new-arrivals {
  margin-top: 15px;
}
