.global-metrics {
  padding: 5px 20px;
  p {
    font-weight: bold;
    margin: 0;
    padding: 5px
  }
  span {
    font-weight: normal;  
  }
}