/**  =====================
      Switches css start
==========================  **/

.switch {
    input[type=checkbox] {
        opacity: 0;
        position: absolute;

        + .form-check-label,
        + .cr {
            position: relative;
            display: inline-block;
            transition: 0.4s ease;
            height: 20px;
            width: 35px;
            border: 1px solid #e9eaec;
            border-radius: 60px;
            cursor: pointer;
            z-index: 0;
            top: 12px;

            &:after,
            &:before {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                left: 0;
            }

            &:before {
                transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
                height: 20px;
                width: 35px;
                border-radius: 30px;
            }

            &:after {
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
                transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
                background: #f7f7f7;
                height: 19px;
                width: 19px;
                border-radius: 60px;
            }
        }

        &:checked + .form-check-label,
        &:checked + .cr {
            &:before {
                background: $theme-color;
                transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
            }

            &:after {
                left: 16px;
            }
        }

        &:disabled + label {
            opacity: 0.5;
            filter: grayscale(0.4);
            cursor: not-allowed;
        }
    }
    @each $value in $color-bt-name {
        $i: index($color-bt-name, $value);

        &.switch-#{$value} input[type=checkbox]:checked + .form-check-label:before,
        &.switch-#{$value} input[type=checkbox]:checked + .cr:before {
            background: nth($color-bt-color,$i);
        }
    }

    &.switch-alternative input[type=checkbox]:checked + .form-check-label:before,
    &.switch-alternative input[type=checkbox]:checked + .cr:before {
        background: $theme-color2;
    }
}
/**====== Switches css end ======**/
.switch{
    .invalid-feedback{
        position: absolute;
        width: max-content;
        top: calc(100% + 15px);
    }
    .form-check{
        input[type=checkbox] + .form-check-label{
            top: 4px;
        }
    }
}