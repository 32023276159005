.pcoded-navbar {
  .datta-scroll.navbar-content {
    height: calc(100vh - 140px);

    @media (max-width: 991px) {
      height: calc(100vh - 70px);
    }
  } 

  .footer-button {
    &.mobile {
      width: $Menu-collapsed-width;
      padding-left: 33px;
    }

    @media (max-width: 991px) {
      width: $Menu-collapsed-width;
      padding-left: 33px;
    }
    
    position: relative;
    align-items: center;
    display: inline-flex;
    float: left;
    height: 70px;
    text-align: center;
    width: 264px;
    margin-right: 0;
    padding: 10px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}
